exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-jaworzno-js": () => import("./../../../src/pages/cennik-jaworzno.js" /* webpackChunkName: "component---src-pages-cennik-jaworzno-js" */),
  "component---src-pages-cennik-katowice-js": () => import("./../../../src/pages/cennik-katowice.js" /* webpackChunkName: "component---src-pages-cennik-katowice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lekcje-online-js": () => import("./../../../src/pages/lekcje-online.js" /* webpackChunkName: "component---src-pages-lekcje-online-js" */),
  "component---src-pages-nauka-gry-na-gitarze-basowej-js": () => import("./../../../src/pages/nauka-gry-na-gitarze-basowej.js" /* webpackChunkName: "component---src-pages-nauka-gry-na-gitarze-basowej-js" */),
  "component---src-pages-nauka-gry-na-gitarze-jaworzno-js": () => import("./../../../src/pages/nauka-gry-na-gitarze-jaworzno.js" /* webpackChunkName: "component---src-pages-nauka-gry-na-gitarze-jaworzno-js" */),
  "component---src-pages-nauka-gry-na-gitarze-katowice-js": () => import("./../../../src/pages/nauka-gry-na-gitarze-katowice.js" /* webpackChunkName: "component---src-pages-nauka-gry-na-gitarze-katowice-js" */),
  "component---src-pages-nauka-gry-na-pianinie-js": () => import("./../../../src/pages/nauka-gry-na-pianinie.js" /* webpackChunkName: "component---src-pages-nauka-gry-na-pianinie-js" */),
  "component---src-pages-nauka-spiewu-jaworzno-js": () => import("./../../../src/pages/nauka-spiewu-jaworzno.js" /* webpackChunkName: "component---src-pages-nauka-spiewu-jaworzno-js" */),
  "component---src-pages-nauka-spiewu-katowice-js": () => import("./../../../src/pages/nauka-spiewu-katowice.js" /* webpackChunkName: "component---src-pages-nauka-spiewu-katowice-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-prowadzacy-js": () => import("./../../../src/pages/prowadzacy.js" /* webpackChunkName: "component---src-pages-prowadzacy-js" */)
}

