import React from 'react'
import { Link } from 'gatsby'

const MyFooter = ({ children }) => (
  <footer className="footer">
    <div className="wrapper">
      {children}
      {/* Dodaj linki do podstron w stopce tutaj */}
  
    </div>
  </footer>
)

export default MyFooter