import { Link } from 'gatsby'
import React, { Component } from 'react'

import logoSmall from '../images/logo-small.png'
import logoName from '../images/logo-name.png'
import * as headerStyles from '../styles/my-header.module.css'

class MyHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuVisible: false,
    }

    this.closeMenu = this.closeMenu.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  closeMenu() {
    this.setState({ menuVisible: false })
  }

  toggleMenu() {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    return (
      <header className="header">
        <div className="header__content">
          <Link className="logo" to="/" onClick={this.closeMenu}>
            <img src={logoSmall} alt="logo" className="logo-image" />
            <img src={logoName} alt="logo-name" className="logo-name" />
          </Link>
          <nav className="flex-nav">
            <label htmlFor="toggle-1" className="toggle-menu">
              ☰ Menu
            </label>
            <input
              type="checkbox"
              id="toggle-1"
              checked={this.state.menuVisible}
              onChange={this.toggleMenu}
            />

            <ul>
              <li>
                <Link to="/" onClick={this.closeMenu}>
                  Start
                </Link>
              </li>
              <li className={headerStyles.dropdownMenu}>
                <Link to="/oferta" onClick={this.closeMenu}>
                  Oferta
                </Link>
                <div className={headerStyles.dropdownContent}>
                  <Link to="/nauka-gry-na-gitarze-jaworzno" onClick={this.closeMenu}>
                    Gitara - Jaworzno
                  </Link>
                  <Link to="/nauka-gry-na-gitarze-katowice" onClick={this.closeMenu}>
                    Gitara - Katowice
                  </Link>
                  <Link to="/nauka-spiewu-jaworzno" onClick={this.closeMenu}>
                    Śpiew - Jaworzno
                  </Link>
                  <Link to="/nauka-spiewu-katowice" onClick={this.closeMenu}>
                    Śpiew - Katowice
                  </Link>
                  <Link to="/nauka-gry-na-pianinie" onClick={this.closeMenu}>
                    Pianino 
                  </Link>
                  <Link to="/nauka-gry-na-gitarze-basowej" onClick={this.closeMenu}>
                    Gitara basowa
                  </Link>
                </div>
              </li>
              <li>
                <Link to="/prowadzacy" onClick={this.closeMenu}>
                  Prowadzący
                </Link>
              </li>
              <li>
                <Link to="/kontakt" onClick={this.closeMenu}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

export default MyHeader
