import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import MyHeader from './my-header'
// import Header from './header'
import MyFooter from './my-footer'
import './layout.css'
import Transition from './transition'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <MyHeader />
        <Transition location={location}>
          <div className="main-content">{children}</div>
        </Transition>
        <MyFooter>
          &copy; {new Date().getFullYear()} Music Spot
        </MyFooter>
        <FbMessanger />
      </>
    )}
  />
)

let gtag_report_conversion = () => {}

const FbMessanger = props => {
  useEffect(() => {
    gtag_report_conversion = url => {
      var callback = function() {
        if (typeof url != 'undefined') {
          window.location = url
        }
      }
      window.gtag('event', 'conversion', {
        send_to: 'AW-467307250/EsVKCJmW-IYDEPKV6t4B',
        event_callback: callback,
      })
      return false
    }

    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.text = `
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "827724387334655");
      chatbox.setAttribute("attribution", "biz_inbox");

      window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v11.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    `
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      {/* <!-- Messenger Wtyczka czatu Code --> */}
      <div id="fb-root" />

      {/* <!-- Your Wtyczka czatu code --> */}
      <div
        id="fb-customer-chat"
        className="fb-customerchat"
        onClick={() => gtag_report_conversion()}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
